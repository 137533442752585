import { Newcustomer } from '../../factories/staticFormsfactory'
import { Customers } from '../../factories/staticListsfactory'
import { getClients } from '../../services/client.services';
import moment from 'moment'

export const CustomerMixin = {
    data() {
        return {
            paginated: {},
            fields: [],
            configs: {},
            button: {},
            clients: [],
            updated: '',
            loading: true
        };
    },
    created() {

        this.createFields()
        this.createList()

    },
    mounted() {

        this.cliente()

    },
    watch: {

    },
    computed: {


    },
    methods: {

        cliente() {
            this.loading = true
            getClients()
                .then((res) => {
                    this.clients = JSON.parse(res.data.data).clients
                    this.clients.filter(el => {
                        el.created_date.value = new Date(el.created_date.value)
                        el.created_date.value = moment(el.created_date.value).format('L')

                        if (el.expiry_date.value !== '') {

                            el.expiry_date.value = moment(new Date(el.expiry_date.value.$date)).format('L')


                        } else {
                            el.expiry_date.value = ''
                        }
                        el.users = el.usercount
                        return el
                    })
                    this.$forceUpdate()
                    this.loading = false


                })
                .catch((e) => {
                    this.loading = false
                    this.$tokentest(e.response.status)
                })
        },
        createList() {
            let list = new Customers()
            this.paginated = list.paginateFields(list.fieldsList, 1, 5)
        },

        createFields() {
            let form = new Newcustomer();
            this.configs.endpoint = form.endpoint
            this.configs.method = form.method
            this.fields = form.fields
            this.button = form.button
        },
        recharge(value) {
            this.updated = value
        }

    }

};