<template>
  <div class="container-fluid customer">
        <LoaderComponent v-if="loading" />
    <div class="container list">
      <List v-if="clients.length > 0" :fields="{}" :type="'customers'" :numberpages='5' :updated.sync="updated" :title="'Lista de Clientes'"/>
    </div>
    <div class="container forms">
      <Form :fields="fields" :config="configs" :type="'customers'" :button="button" @recharge="recharge"/>
      
    </div>
  </div>
</template>
<script type="text/javascript">
import { CustomerMixin } from "./Customer.mixin.js";
import Form from "../../components/ui/form/form";
import List from "../../components/ui/list/list";
import LoaderComponent from "../../components/ui/loader/loader";

export default {
  props: {},
  components: {
    Form,
    List,
    LoaderComponent
    
  },
  name: "Customer",
  mixins: [CustomerMixin],
};
</script>
<style src="./Customer.less" lang="less" />
